import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import cep from 'cep-promise';
import InformAddress from './InformAddress';
import GooglePlacesInput from './GooglePlacesInput';
import geolocate from '../lib/geolocate';
import Address from './Address';
import StartAddressInput from './StartAddressInput';
import placeToAddress from '../lib/place-to-address';
import i18n from '../../../javascript/js/i18n';

const AddressInputModalContent = function ({
  googleMapsKey,
  canInputFreightPrice,
  isOutOfRange,
  onHide,
  onSelectAddress,
  onSearchAddress,
  addresses,
  searchedAddress,
  onConfirmedAddress,
  onWrongAddress,
  question,
  outOfRange,
  loading,
  useCurrentPositionLabel,
  zipcodeLabel,
  streetLabel,
  numberLabel,
  complementLabel,
  referenceLabel,
  cityLabel,
  neighborhoodLabel,
  freightPriceLabel,
  freightPriceHint,
  freightType,
  confirmLabel,
  deliveryLabel,
  pickupLabel,
  searchLabel,
  currency,
  zipcodeMask,
  city,
  country,
  enableDelivery,
  enablePickup,
  servesLocal,
  deliveryMethod,
  onChangeDeliveryMethod,
  enableCurrentPosition,
  enableAutocompleteAddressInput,
  enableZipcodeInput,
  address,
  searchButton,
  fullScreen,
  isSignedIn,
  minWait,
  maxWait,
  open,
  latitude,
  longitude,
  radius,
}) {
  const theme = useTheme();
  const [isGeolocating, setIsGeolocating] = useState(false);

  const handleDeliveryMethodChange = (event) => {
    onChangeDeliveryMethod(event.target.value);
  };

  const renderAddresses = () => (addresses || []).map((persistedAddress) => {
    const {
      street, number, complement, neighborhood, addressCity, addressState,
    } = persistedAddress;
    const key = `${street}-${number}-${complement}`;
    return (
      <button
        type="button"
        key={key}
        className="border rounded mt-3 p-3 w-100 text-left address"
        onClick={() => { onConfirmedAddress(persistedAddress); }}
        style={{ cursor: 'pointer' }}
        role="menuitem"
        tabIndex={0}
      >
        <Address
          street={street}
          number={number}
          complement={complement}
          neighborhood={neighborhood}
          city={addressCity}
          state={addressState}
          zipcode={persistedAddress.zipcode}
        />
      </button>
    );
  });

  const usePosition = () => {
    setIsGeolocating(true);
    geolocate(googleMapsKey, (err, location) => {
      const placeAddress = placeToAddress(location.results[0]);
      setIsGeolocating(false);
      onSelectAddress({ ...placeAddress, geolocated: true });
    });
  };

  const submitNeighborhood = (event, neighborhood) => {
    event.preventDefault();
    onSelectAddress({ neighborhood, city, country });
  };

  const submitZipcode = (event, zipcode) => {
    event.preventDefault();
    cep(zipcode).then((json) => {
      onSelectAddress({ ...json, zipcode, country });
    }).catch(onWrongAddress);
  };

  const hasAddress = () => address && Object.keys(address).length > 0;

  const enableInformAddress = () => hasAddress() || !enableAutocompleteAddressInput;

  const goToSignIn = (e) => {
    e.preventDefault();
    onHide();
    window.openSignInModal();
  };

  return (
    <>
      {isOutOfRange && (
        <div className="px-4 py-3 alert alert-danger mb-0">{outOfRange}</div>
      )}
      <DialogTitle>
        <Typography color="primary" display="inline" variant="inherit">{question}</Typography>
        <IconButton
          aria-label="close"
          className="float-right p-0 m-0 close-address-form"
          onClick={onHide}
          style={{ top: '5px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <>
          <p className="introduction">{i18n.addressInputModalContentDescription}</p>
          <RadioGroup value={deliveryMethod} onChange={handleDeliveryMethodChange}>
            {servesLocal && (
              <>
                <FormControlLabel value="consume_at_place" control={<Radio />} label={i18n.gonnaConsumeAtPlace} />
                {open && minWait && (
                  <small className="text-muted mb-3" style={{ marginLeft: 30, marginTop: -15 }}>
                    {i18n.readyIn(minWait)}
                  </small>
                )}
              </>
            )}
            {enablePickup && (
              <>
                <FormControlLabel value="pickup" control={<Radio />} label={pickupLabel} />
                {open && minWait && (
                  <small className="text-muted mb-3" style={{ marginLeft: 30, marginTop: -15 }}>
                    {i18n.pickupIn(minWait)}
                  </small>
                )}
              </>
            )}
            {enableDelivery && (
              <>
                <FormControlLabel value="delivery" control={<Radio />} label={deliveryLabel} />
                {open && minWait && (
                  <small className="text-muted mb-3" style={{ marginLeft: 30, marginTop: -15 }}>
                    {i18n.deliveryTimeBetween(minWait, maxWait)}
                  </small>
                )}
              </>
            )}
          </RadioGroup>
          {deliveryMethod === 'delivery' && (
            <>
              {isSignedIn === undefined && (
                <div
                  className="d-flex py-3 px-2 rounded-lg mb-3"
                  style={{
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  <i
                    className="fas fa-info-circle ml-1 mr-2 my-auto"
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                  <div>
                    <a // eslint-disable-line jsx-a11y/anchor-is-valid
                      href="#"
                      onClick={goToSignIn}
                      className="mr-1"
                    >
                      {i18n.doSignIn}
                    </a>
                    {i18n.toSaveYourAddress}
                    .
                  </div>
                </div>
              )}
              {(canInputFreightPrice && enableInformAddress()) ? (
                <InformAddress
                  onInformAddress={onConfirmedAddress}
                  zipcodeMask={zipcodeMask}
                  zipcodeLabel={zipcodeLabel}
                  streetLabel={streetLabel}
                  numberLabel={numberLabel}
                  complementLabel={complementLabel}
                  referenceLabel={referenceLabel}
                  cityLabel={cityLabel}
                  neighborhoodLabel={neighborhoodLabel}
                  freightPriceLabel={freightPriceLabel}
                  freightPriceHint={freightPriceHint}
                  confirmLabel={confirmLabel}
                  currency={currency}
                  country={country}
                  address={address}
                />
              ) : (
                <>
                  {enableCurrentPosition && (
                    <button
                      type="button"
                      className="btn btn-link px-0"
                      onClick={usePosition}
                      style={{ cursor: 'pointer' }}
                      role="menuitem"
                      tabIndex={0}
                    >
                      <i className="fas fa-crosshairs mr-2" />
                      {isGeolocating ? loading : useCurrentPositionLabel}
                    </button>
                  )}
                  {enableAutocompleteAddressInput ? (
                    <GooglePlacesInput
                      address={searchedAddress}
                      onSearch={onSearchAddress}
                      onSelect={onSelectAddress}
                      loadingLabel={loading}
                      fullScreen={fullScreen}
                      latitude={latitude}
                      longitude={longitude}
                      radius={radius}
                    />
                  ) : (
                    <StartAddressInput
                      country={country}
                      enableZipcodeInput={enableZipcodeInput}
                      submitNeighborhood={submitNeighborhood}
                      submitZipcode={submitZipcode}
                      searchLabel={searchLabel}
                      zipcodeMask={zipcodeMask}
                      searchButton={searchButton}
                      freightType={freightType}
                    />
                  )}
                  {renderAddresses()}
                </>
              )}
            </>
          )}
          {(deliveryMethod === 'pickup' || deliveryMethod === 'consume_at_place') && (
            <div className="d-block mt-4">
              <Button
                variant="contained"
                disableElevation
                color="primary"
                fullWidth
                type="button"
                onClick={onHide}
              >
                {confirmLabel}
              </Button>
            </div>
          )}
        </>
      </DialogContent>
    </>
  );
};

export default AddressInputModalContent;
