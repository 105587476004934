import 'core-js/features/array/flat-map';
import { v4 as uuidv4 } from 'uuid';
import track from '../../../../javascript/js/fbq-track';
import itemTotal, { itemsSubtotal, cartTotal } from '../item-total';
import round from '../round';

const buildVariations = (item) => item.product.variations.flatMap((variation) => {
  const inputs = document.querySelectorAll(`#variation-${variation.id} input`);
  const items = Array.from(inputs).map((input) => {
    const id = parseInt(input.dataset.id, 10);
    if (input.checked) {
      return { id, quantity: 1 };
    } if (input.type === 'text' && input.value > 0) {
      return { id, quantity: parseInt(input.value, 10) };
    }
    return null;
  }).filter((variationItem) => variationItem !== null);
  return {
    id: variation.id, reducer: variation.reducer, max: variation.max, items,
  };
});

const newItem = (action) => ({
  quantity: 1, product: action.payload, variations: [], uuid: uuidv4(),
});

const calculateFreightPrice = (address, subtotal, freeFreightLimit) => {
  if (address
    && (freeFreightLimit === undefined
      || freeFreightLimit === null
      || subtotal <= freeFreightLimit)) {
    return parseFloat(address.price);
  }
  return 0;
};

const calculateServiceFee = (items, serviceTax, enableServiceTax) => {
  if (enableServiceTax && serviceTax) {
    return itemsSubtotal(items) * (serviceTax / 100.0);
  }
  return 0;
};

export const initialState = {
  addressNeeded: false,
  askForAddressOn: 'add_item',
  canInputFreightPrice: false,
  channel: null,
  confirmingAddress: null,
  confirmedAddress: null,
  currentIndex: null,
  currentItem: null,
  cardCode: null,
  change: null,
  deliverAutomatically: false,
  deliveryMethod: 'delivery',
  displayDeliverInput: false,
  displayMissingPayment: false,
  subtotal: 0,
  freightPrice: 0,
  freeFreightLimit: null,
  discount: 0,
  discountPercentage: 0,
  discountType: 'fixed_value',
  donation: 0,
  document: null,
  serviceTax: 0,
  serviceFee: 0,
  enableServiceTax: false,
  enableItemObservations: true,
  allowPickups: false,
  enableDelivery: true,
  items: [],
  isCollapsed: (window.innerWidth < 992),
  isOnTrust: false,
  isOutOfRange: false,
  missingPayment: null,
  payments: [],
  paymentDiscount: 0,
  promotionDiscount: 0,
  searchedAddress: '',
  showDetails: false,
  table: null,
  totalPayment: 0,
  userBirthdate: null,
  userMobilePhone: null,
  userObservations: null,
  userName: null,
};

export default (state = initialState, action = {}) => {
  const calculateMissingPayment = (changes) => {
    const newState = { ...state, ...changes };
    const total = cartTotal(newState);
    const { totalPayment } = newState;
    return totalPayment !== null ? round(total - totalPayment, 2) : null;
  };

  const calculateMissingPaymentAndMerge = (changes) => {
    const missingPayment = calculateMissingPayment(changes);
    return { ...state, ...changes, missingPayment };
  };

  const calculateTotalPaymentAndMissingPaymentAndMerge = (payments) => {
    const sum = (total, payment) => total + parseFloat(payment.total);
    const totalPayment = round(payments.reduce(sum, 0.0), 2);
    return calculateMissingPaymentAndMerge({ payments, totalPayment });
  };

  switch (action.type) {
    case 'ADD_ITEM': {
      const items = state.items.concat(action.payload);
      const subtotal = itemsSubtotal(items);
      const serviceFee = calculateServiceFee(items, state.serviceTax, state.enableServiceTax);
      const freightPrice = calculateFreightPrice(
        state.confirmedAddress,
        subtotal,
        state.freeFreightLimit,
      );
      return calculateMissingPaymentAndMerge({
        items, subtotal, serviceFee, freightPrice,
      });
    }
    case 'ADD_PAYMENT': {
      const timestamp = Date.now();
      const payments = state.payments.concat({ ...action.payload, timestamp });
      return calculateTotalPaymentAndMissingPaymentAndMerge(payments);
    }
    case 'CANCEL': {
      return {
        ...initialState,
        channel: state.channel,
        deliveryMethod: state.deliveryMethod,
        displayDeliverInput: true,
        displayMissingPayment: true,
      };
    }
    case 'CLICK_PRODUCT': {
      const item = newItem(action);
      return {
        ...state,
        currentIndex: null,
        currentItem: item,
        showDetails: true,
      };
    }
    case 'CLOSE_ADDRESS_DIALOG': {
      return { ...state, addressNeeded: false };
    }
    case 'CONFIRM_GOOD_ADDRESS': {
      const freightPrice = calculateFreightPrice(
        action.payload,
        state.subtotal,
        state.freeFreightLimit,
      );
      return calculateMissingPaymentAndMerge({
        isOutOfRange: false,
        addressNeeded: false,
        confirmedAddress: action.payload,
        freightPrice,
      });
    }
    case 'CONFIRM_WRONG_ADDRESS': {
      return calculateMissingPaymentAndMerge({
        isOutOfRange: true,
        searchedAddress: '',
        confirmingAddress: null,
        confirmedAddress: null,
        freightPrice: 0,
      });
    }
    case 'COLLAPSE': {
      return { ...state, isCollapsed: true };
    }
    case 'EDIT_ADDRESS': {
      const confirmingAddress = state.confirmedAddress;
      return {
        ...state,
        confirmingAddress,
        addressNeeded: true,
        isOutOfRange: false,
      };
    }
    case 'ENABLE_SERVICE_TAX': {
      const enableServiceTax = action.payload;
      const serviceFee = calculateServiceFee(state.items, state.serviceTax, enableServiceTax);
      return calculateMissingPaymentAndMerge({ serviceFee, enableServiceTax });
    }
    case 'EXIT_DETAILS': {
      return {
        ...state,
        currentIndex: null,
        currentItem: null,
        showDetails: false,
      };
    }
    case 'EDIT_ITEM': {
      const currentIndex = action.payload;
      const currentItem = state.items[currentIndex];
      return {
        ...state, showDetails: true, currentIndex, currentItem,
      };
    }
    case 'LOAD_ADDRESS': {
      return { ...state, confirmingAddress: action.payload, addressNeeded: true };
    }
    case 'MERGE_STATE': {
      return { ...state, ...action.payload };
    }
    case 'RESET': {
      return { ...initialState };
    }
    case 'RESET_TO': {
      const { payload } = action;
      return { ...payload };
    }
    case 'RESET_SEARCHED_ADDRESS': {
      return {
        ...state,
        searchedAddress: '',
        confirmingAddress: null,
      };
    }
    case 'REMOVE_ITEM': {
      const items = state.items.slice(0);
      items.splice(action.payload, 1);
      const subtotal = itemsSubtotal(items);
      const serviceFee = calculateServiceFee(items, state.serviceTax, state.enableServiceTax);
      const freightPrice = calculateFreightPrice(
        state.confirmedAddress,
        subtotal,
        state.freeFreightLimit,
      );
      return calculateMissingPaymentAndMerge({
        items, subtotal, serviceFee, freightPrice,
      });
    }
    case 'REMOVE_PAYMENT': {
      const otherTimestamp = (statePayment) => statePayment.timestamp !== action.payload.timestamp;
      const payments = state.payments.filter(otherTimestamp);
      return { ...state, ...calculateTotalPaymentAndMissingPaymentAndMerge(payments) };
    }
    case 'SEARCH_ADDRESS': {
      return { ...state, searchedAddress: action.payload };
    }
    case 'SELECT_ADDRESS': {
      return { ...state, confirmingAddress: action.payload };
    }
    case 'SET_CHANNEL': {
      return { ...state, channel: action.payload };
    }
    case 'SET_DELIVERY_METHOD': {
      const enableServiceTax = action.payload === 'served';
      const serviceFee = calculateServiceFee(state.items, state.serviceTax, enableServiceTax);
      return calculateMissingPaymentAndMerge({
        serviceFee,
        deliveryMethod: action.payload,
        enableServiceTax,
        confirmedAddress: null,
      });
    }
    case 'SET_DISCOUNT': {
      const discount = action.payload;
      return calculateMissingPaymentAndMerge({ discount });
    }
    case 'SET_DISCOUNT_PERCENTAGE': {
      const discountPercentage = action.payload;
      return calculateMissingPaymentAndMerge({ discountPercentage });
    }
    case 'SET_DISCOUNT_TYPE': {
      const discountType = action.payload;
      return calculateMissingPaymentAndMerge({ discountType });
    }
    case 'SET_DONATION': {
      const donation = action.payload;
      return calculateMissingPaymentAndMerge({ donation });
    }
    case 'SET_IS_ON_TRUST': {
      return { ...state, isOnTrust: action.payload };
    }
    case 'SET_ITEM': {
      const { deliveryMethod, confirmedAddress, askForAddressOn } = state;
      const addressNeeded = deliveryMethod === 'delivery'
        && confirmedAddress === null
        && askForAddressOn === 'add_item';
      let items = state.items.slice(0);
      if (state.currentIndex === null) {
        if (state.currentItem.quantity > 0) {
          const item = state.currentItem;
          items = state.items.concat(item);
          track('AddToCart', {
            content_type: 'product',
            content_name: item.product.name,
            value: itemTotal(item),
            currency: document.querySelector('html').dataset.currency,
            contents: [{ id: item.product.id, quantity: item.quantity }],
          });
        }
      } else if (state.currentItem.quantity > 0) {
        items[state.currentIndex] = state.currentItem;
      } else {
        items.splice(state.currentIndex, 1);
      }
      const subtotal = itemsSubtotal(items);
      const serviceFee = calculateServiceFee(items, state.serviceTax, state.enableServiceTax);
      const freightPrice = calculateFreightPrice(
        state.confirmedAddress,
        subtotal,
        state.freeFreightLimit,
      );
      const newState = calculateMissingPaymentAndMerge({
        items,
        subtotal,
        serviceFee,
        freightPrice,
        showDetails: false,
        currentIndex: null,
        currentItem: null,
        searchedAddress: '',
        confirmingAddress: null,
      });
      return {
        ...newState,
        addressNeeded,
      };
    }
    case 'SET_PROMOTION_DISCOUNT': {
      const promotionDiscount = action.payload;
      return calculateMissingPaymentAndMerge({ promotionDiscount });
    }
    case 'SET_TOTAL_PAYMENT': {
      return calculateMissingPaymentAndMerge(action.payload);
    }
    case 'SET_CARD_CODE': {
      return { ...state, cardCode: action.payload };
    }
    case 'SET_DELIVER_AUTOMATICALLY': {
      return { ...state, deliverAutomatically: action.payload };
    }
    case 'SET_DOCUMENT': {
      return { ...state, document: action.payload };
    }
    case 'SET_TABLE': {
      return { ...state, table: action.payload };
    }
    case 'SET_USER_BIRTHDATE': {
      return { ...state, userBirthdate: action.payload };
    }
    case 'SET_USER_MOBILE_PHONE': {
      return { ...state, userMobilePhone: action.payload };
    }
    case 'SET_USER_NAME': {
      return { ...state, userName: action.payload };
    }
    case 'SET_USER_OBSERVATIONS': {
      return { ...state, userObservations: action.payload };
    }
    case 'UNCOLLAPSE': {
      return { ...state, isCollapsed: false };
    }
    case 'UPDATE_OBSERVATIONS': {
      const item = { ...state.currentItem, observations: action.payload };
      return { ...state, currentItem: item };
    }
    case 'UPDATE_QUANTITY': {
      const item = { ...state.currentItem, quantity: action.payload };
      return { ...state, currentItem: item };
    }
    case 'UPDATE_VARIATIONS': {
      const item = { ...state.currentItem, variations: buildVariations(state.currentItem) };
      return { ...state, currentItem: item };
    }
    default: {
      return state;
    }
  }
};
