import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import action from '../../lib/action';
import i18n from '../../../../javascript/js/i18n';

const ChooseChannel = function ({ dispatch, table }) {
  const hasTableParam = new URLSearchParams(window.location.search).get('table');
  if (table && !hasTableParam) {
    const url = new URL(window.location);
    url.searchParams.append('table', table.id);
    const goToDelivery = () => {
      dispatch(action('RESET'));
      window.location = '/exit';
    };
    return (
      <Dialog open>
        <DialogContent>
          <div className="lead mb-4">
            {i18n.chooseOptionToContinue}
          </div>
          <div className="text-center">
            <Button
              variant="outlined"
              color="primary"
              className="mx-2 mb-3"
              size="large"
              href={url.href}
              fullWidth
            >
              {i18n.continueOnTable}
              {' '}
              {table.name}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="mx-2 mb-3"
              size="large"
              onClick={goToDelivery}
              fullWidth
            >
              {i18n.goToDelivery}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  return '';
};

export default ChooseChannel;
