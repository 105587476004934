import action from '../action';

const objectToQueryString = (object) => {
  const toQueryArray = (arr, key) => (object[key] ? arr.concat(`${key}=${object[key]}`) : arr);
  const keys = Object.keys(object);
  const attributes = keys.reduce(toQueryArray, []);
  return attributes.join('&');
};

const addressToQueryString = (address) => {
  const {
    latitude, longitude, street, number, neighborhood, city, zipcode, state, country,
  } = address;
  const object = {
    latitude, longitude, street, number, neighborhood, city, zipcode, state, country,
  };
  return objectToQueryString(object);
};

export default (slug, address, dispatch) => {
  fetch(`/internal/freight?from=${slug}&${addressToQueryString(address)}`).then((response) => {
    response.json().then((freight) => {
      if (freight.price) {
        dispatch(action('CONFIRM_GOOD_ADDRESS', { ...address, ...freight }));
      } else {
        dispatch(action('CONFIRM_WRONG_ADDRESS'));
      }
    });
  });
};
