import 'core-js/features/array/flat-map';

const sum = (total, value) => total + value;

const max = (a, b) => Math.max(a, b);

const findProductVariation = (item, variation) => {
  const variationById = (otherVariation) => otherVariation.id === variation.id;
  return item.product.variations.find(variationById)
    || item.variations?.find(variationById);
};

const findProductVariationItem = (variation, item) => {
  const itemById = (variationItem) => variationItem.id === item.id;
  return variation.items.find(itemById);
};

const mapVariationTotals = (variation, productVariation) => variation.items.map((variationItem) => {
  const productVariationItem = findProductVariationItem(productVariation, variationItem);
  const price = productVariationItem ? productVariationItem.price : variationItem.price;
  return parseFloat(price, 10) * variationItem.quantity;
});

const mapVariationPrices = (variation, productVariation) => variation.items.map((variationItem) => {
  const productVariationItem = findProductVariationItem(productVariation, variationItem);
  return parseFloat(productVariationItem.price, 10);
});

const variationsPrice = (item) => (item.variations || []).map((variation) => {
  const productVariation = findProductVariation(item, variation);
  if (productVariation.reducer === 'summing') {
    return mapVariationTotals(variation, productVariation).reduce(sum, 0);
  }
  return mapVariationPrices(variation, productVariation).reduce(max, 0);
}).reduce(sum, 0);

const calculateUnitPrice = (item) => parseFloat(item.product.price) + variationsPrice(item);

const itemUnitPrice = (item) => item.unitPrice || calculateUnitPrice(item) || 0;

const itemTotal = (item) => itemUnitPrice(item) * item.quantity;

export default itemTotal;

export const itemsSubtotal = (items) => {
  const sumItemTotal = (total, item) => total + itemTotal(item);
  return parseFloat(items.reduce(sumItemTotal, 0.0).toFixed(2));
};

export const cartTotal = (cart) => {
  const {
    subtotal,
    freightPrice,
    discountType,
    discount,
    discountPercentage,
    donation,
    serviceFee,
    paymentDiscount,
    promotionDiscount,
  } = cart;
  const discountValue = discountType === 'fixed_value' ? (parseFloat(discount) || 0) : (subtotal * (parseFloat(discountPercentage) || 0)) / 100.0;
  return subtotal + serviceFee + freightPrice - discountValue - paymentDiscount - promotionDiscount + donation;
};
