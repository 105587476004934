import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import Variations from 'alisto.js/lib/components/Variations';
import ProductQuantity from 'alisto.js/lib/components/ProductQuantity';
import toCurrency from 'alisto.js/lib/to-currency';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import track from '../js/fbq-track';
import i18n from '../js/i18n';
import itemTotal from '../../assets/javascripts/lib/item-total';
import action from '../../assets/javascripts/lib/action';

const mapStateToProps = (state) => ({
  state,
  isUpdate: (state.currentIndex !== null),
  show: state.showDetails,
  item: state.currentItem,
  enableItemObservations: state.enableItemObservations,
});

const mapDispatchToProps = (dispatch) => ({
  onHide: () => dispatch(action('EXIT_DETAILS')),
  onQuantityChange: (quantity) => dispatch(action('UPDATE_QUANTITY', quantity)),
  onObservationsChange: (observations) => dispatch(action('UPDATE_OBSERVATIONS', observations)),
  onSubmit: () => dispatch(action('SET_ITEM')),
  onVariationChange: () => dispatch(action('UPDATE_VARIATIONS')),
});

const Transition = React.forwardRef((props, ref) => <Grow ref={ref} {...props} />);

const ItemDetails = function ({
  state,
  show,
  onHide,
  item,
  onQuantityChange,
  onSubmit,
  isUpdate,
  removeLabel,
  onVariationChange,
  onObservationsChange,
  title,
  observationsLabel,
  addLabel,
  updateLabel,
  cancelLabel,
  enableItemObservations,
  enableNumericInputs = false,
  showCover = false,
  onFinish = null,
  readonly = false,
}) {
  const submitButton = useRef(null);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [maxQuantity, setMaxQuantity] = useState(999);

  const onEnter = () => {
    if (item && item.product) {
      if (item.product.limitable) {
        const url = `/internal/max-quantity.json?u=${item.product.id}`;
        fetch(url).then((response) => response.json()).then((json) => {
          const sameProductItems = state.items.filter((stateItem) => stateItem !== item
                    && stateItem.product
                    && stateItem.product.id === item.product.id);
          const sumQuantity = (total, sameProductItem) => total + sameProductItem.quantity;
          const reserved = sameProductItems.reduce(sumQuantity, 0);
          setMaxQuantity(json.max - reserved);
        });
      }
      onVariationChange();
    }
    track('ViewContent', {
      content_type: 'product',
      content_name: item.product.name,
      contents: [{
        id: item.product.id,
        quantity: item.quantity,
      }],
    });
  };

  if (item && (item.product === null || item.product === undefined)) {
    return '';
  }

  const total = () => (item ? itemTotal(item) : 0);

  const submit = (event) => {
    event.preventDefault();
    if (onFinish) {
      onFinish();
    }
    onSubmit();
  };

  const changeObservations = (event) => {
    event.preventDefault();
    onObservationsChange(event.target.value);
  };

  const submitLabel = isUpdate ? updateLabel : addLabel;
  const exitLabel = isUpdate ? removeLabel : cancelLabel;

  const submitForm = () => {
    document.getElementById('form-submit').click();
  };

  const focusButton = () => {
    if (!readonly) {
      submitButton.current.focus();
    }
  };

  const available = item && item.product.inStock && (item.product.categoryAvailable !== false);
  const { currency } = document.querySelector('html').dataset;

  const onClose = () => {
    if (onFinish) {
      onFinish();
    }
    onHide();
  };

  const renderFooter = () => {
    if (readonly) {
      return (
        <Typography
          color="primary"
          className="my-auto mr-2"
        >
          <strong>
            Total:
            {' '}
            {toCurrency(total())}
          </strong>
        </Typography>
      );
    } if (available) {
      return (
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={submitForm}
          ref={submitButton}
        >
          {item.quantity > 0 ? `${submitLabel} ${toCurrency(total())}` : exitLabel}
        </Button>
      );
    }
    return (
      <Button variant="contained" disabled ref={submitButton}>
        {!item.product.inStock ? i18n.outOfStock : i18n.unavailable}
      </Button>
    );
  };

  return (
    <>
      {item && show && (
        <Helmet>
          <meta property="og:title" content={item.product.name} />
          <meta property="og:description" content={item.product.description} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={item.product.coverImageUrl} />
          <meta property="product:availability" content={available ? 'in stock' : 'out of stock'} />
          <meta property="product:condition" content="new" />
          <meta property="product:price:amount" content={item.product.price} />
          <meta property="product:price:currency" content={currency} />
          <meta property="product:retailer_item_id" content={item.product.id} />
        </Helmet>
      )}
      <Dialog
        open={show}
        onClose={onClose}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        TransitionProps={{ onEnter, onEntered: focusButton }}
        style={{ zIndex: 2147483643 }}
      >
        <DialogTitle className="pb-0">
          <div className="d-flex justify-content-between">
            <div>{item && item.product.name}</div>
            <div className="my-auto">
              <IconButton
                aria-label="close"
                className="text-secondary float-right p-0 m-0"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {item && (
            <form id="item-details-form" onSubmit={submit}>
              {showCover && item.product.coverImageUrl && (
                <div className="cover-container w-100 text-center pb-3">
                  <img src={item.product.coverImageUrl} className="img-fluid rounded w-100 h-100" style={{ objectFit: 'cover', maxHeight: '400px' }} alt={title} />
                </div>
              )}
              {item.product.description && item.product.description.length > 0 && (
                <p style={{ whiteSpace: 'break-spaces' }}>{item.product.description}</p>
              )}
              <div>
                <Typography color="secondary">{item.product.textPrice}</Typography>
              </div>
              <Variations
                collection={item.product.variations}
                itemVariations={item.variations}
                onChange={onVariationChange}
                enableNumericInput={enableNumericInputs}
                outOfStockLabel={i18n.outOfStock}
                readonly={readonly}
              />
              {enableItemObservations && !readonly && (
                <TextField
                  id="item-observations"
                  label={observationsLabel}
                  multiline
                  fullWidth
                  value={item.observations || ''}
                  onChange={changeObservations}
                  variant="outlined"
                />
              )}
              <input type="submit" id="form-submit" className="d-none" />
            </form>
          )}
        </DialogContent>
        <DialogActions>
          {item && (
            <div className="d-flex justify-content-between pt-3 w-100">
              <div style={{ width: (item.product.enableFractionalSale ? '150px' : '120px') }}>
                {available && (
                <ProductQuantity
                  max={maxQuantity}
                  value={item.quantity}
                  onChange={onQuantityChange}
                  id="item-quantity"
                  fractional={item.product.enableFractionalSale}
                  unit={item.product.unit}
                  enableNumericInput={enableNumericInputs}
                />
                )}
              </div>
              {renderFooter()}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);
