import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PhoneInput from './PhoneInput';
import i18n from '../../../javascript/js/i18n';
import action from '../lib/action';

const CustomerDialog = function ({
  open, onHide, onSubmit, phoneMask,
}) {
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();
  const name = useSelector((state) => state.userName);
  const mobilePhone = useSelector((state) => state.userMobilePhone);

  const submitForm = (e) => {
    e.preventDefault();
    setSubmit(true);
  };

  useEffect(() => {
    if (submit) {
      onSubmit();
    }
  }, [submit, onSubmit]);

  return (
    <Dialog
      open={open}
      style={{ zIndex: 2147483643 }}
    >
      <DialogTitle>
        <Typography color="primary" display="inline" variant="inherit">
          {i18n.confirmYourInfoToContinue}
        </Typography>
        <IconButton
          aria-label="close"
          className="float-right p-0 m-0 close-address-form"
          onClick={onHide}
          style={{ top: '5px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={submitForm}>
          <TextField
            id="customer_name"
            variant="outlined"
            label={i18n.name}
            required
            className="w-100 pb-3"
            value={name || ''}
            onChange={(e) => dispatch(action('SET_USER_NAME', e.target.value))}
          />
          <PhoneInput
            id="customer_phone"
            label={i18n.phone}
            required
            mask={phoneMask}
            className="w-100 pb-3"
            value={mobilePhone || ''}
            onChange={(value) => dispatch(action('SET_USER_MOBILE_PHONE', value))}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            className="mb-2"
            type="submit"
          >
            Continuar
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerDialog;
